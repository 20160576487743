import React from "react";
import { Document, Page } from "react-pdf";
import Pilkahdus from "../assets/pilkahdus.png";
import { Tooltip, Typography } from "@material-ui/core";

import { Grid } from "@material-ui/core";

import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* copy this file locally. */
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function DocumnentViewer(props) {
  function onDocumentLoadSuccess({ numPages }) {
    props.setNumberOfPages(numPages);
  }

  const welcome = () => {
    return (
      <div>
        <div>
          <img src={Pilkahdus} alt="pilkahdus yhteistyön logo" />
        </div>
        <p>
          <strong>Pilkahdus</strong> - yhteistyöverkosto Pirkanmaan
          tulevaisuuden ennakoimiseen
        </p>

        <Grid
          container
          spacing={2}
          style={{
            margin: "auto",
            maxWidth: "70%",
            border: "1px solid lightgrey",
            padding: "10px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{ textAlign: "left" }}
          >
            {" "}
            <p>
              {" "}
              Tulevaisuusfoorumi antaa myös lähtösysäyksen syksyllä
              käynnistyvälle uuden Pirkanmaan maakuntaohjelman valmistelulle.
              Lisätietoja seuraa lähempänä ajankohtaa. Uusi yhteistyö jatkuvan
              oppimisen teemassa Pilkahdus on lyönyt hynttyitä yhteen Tampereen
              korkeakouluyhteisön jatkuvan oppimisen kehittämisen kanssa. On
              suotavaa olla ikuinen opiskelija! Lue lisää Tampereen yliopiston
              sivuilta. <br />
              <br />
              Uusia tietosisältöjä Pilkahduksessa ja muualla Pirkanmaan
              innovaatiotilannekuva 2019 kokoaa yhteen viimeisimpiä
              tietosignaaleja ja tulkintoja alueen verkostosta. Tutustu
              analyyseihin ja blogeihin verkkojulkaisussa. <br />
              <br />
              Erityisenä lukusuosituksena on uusi Pirkanmaan kasvuhakuiset
              -tilannekuva, johon on visualisoitu laaja tietosisältö
              kasvuhakuisten yritysten, ideoiden ja rahoituksen kehittymisestä
              alueella. <br />
              <br />
              Konsulttitoimisto MDI on tuottanut uuden avoimeen dataan
              perustuvan tietopalvelun. Palvelu tarjoaa alueittaisia
              tilannekuvatarkasteluja ja näkymiä muun muassa työllisyyden,
              talouden ja väestön kehittymisestä.
              <br />
              <br /> Pirkanmaan tulevaisuusfoorumi 2019 kokoontui Tampereella
              21.2.2019, aiheena "Hyvä elämä vastakin". Jaetut esitysmateriaalit
              ovat luettavissa Tietopankissa. Ohjelmassa alustivat mm.
              kirjailija Juha Hurme, professori Piia Jallinoja, tutkijatohtori
              Panu Pihkala ja tutkija Katri Joensuu. Nuorten paneeli peilasi
              hyvän elämän muodostumista nuorison näkökulmasta. Näyttää tutulta,
              mutta jotenkin erilaiselta... kyllä, Pilkahdus-sivusto keskittyy
              nyt entistä fokusoidummin ennakointitiedon ja materiaalin
              välittämiseen. Keskusteluja viritellään tapaamisissa ja
              Pilkahduksen some-kanavilla, Twitterissä ja Facebookissa.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Tooltip
              title="Klikkaa tästä Tulevaisuusfoorumin twitter syötteeseen"
              placement="top-start"
            >
              <div>
                <a
                  rel="nofollow"
                  href="https://twitter.com/Pilkahdus_PL?ref_src=twsrc%5Etfw"
                >
                  <FontAwesomeIcon
                    icon={faHashtag}
                    style={{ textAlign: "center", color: "#002c52" }}
                    size="2x"
                  />
                  <Typography
                    variant="subtitle1"
                    style={{ textAlign: "center", color: "#002c52" }}
                  >
                    Pilkahdus@Pirkanmaan liitto
                  </Typography>
                </a>
              </div>
            </Tooltip>
            <br />
            <div className="twitter-feed">
              <script
                async
                src="https://platform.twitter.com/widgets.js"
                charSet="utf-8"
              ></script>
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fpilkahdus&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                width="340"
                height="700"
                scrolling="no"
                frameBorder="0"
                allowtransparency="true"
                title="twitter"
              ></iframe>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  return props.curDocUrl === "" ? (
    <div>{welcome()}</div>
  ) : (
    <div>
      <Document file={props.curDocUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={props.currentPage} />
      </Document>
      <p>
        Sivu {props.currentPage} / {props.numberOfPages}
      </p>
    </div>
  );
}
