import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuBookOutlined from "@material-ui/icons/MenuBookOutlined";
import HomeOutlined from "@material-ui/icons/HomeOutlined";

import DrawerHeaderBar from "./DrawerHeaderBar";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles({
  menuButton: {
    fontSize: 40,
    padding: 0,
    margin: 0,
  },
  docTreeButtonContainer: {
    display: "flex",
    flexDirection: "row",
    zIndex: 20,
    maxWidth: 50,
    padding: 4,
    margin: 2,
  },

  docTreeButton: {
    color: "black",
  },
  docTreeButton2: {
    color: "black",
    marginLeft: "5px",
  },
  row: {
    backgroundColor: "white",
    "&:hover ": {
      backgroundColor: "rgba(229,104,11,0.6)",
    },
  },
  active: {
    backgroundColor: "green",
  },

  // rgba(229,104,11,0.6)
  oddRow: { backgroundColor: "rgba(220,220,220,0.6)" },
});

export default function DocumentDrawer(props) {
  const classes = useStyles();
  const { files } = props;

  const list = () => (
    <div
      onClick={props.toggleDocTree(false)}
      onKeyDown={props.toggleDocTree(false)}
    >
      <List>
        {files.map((text, index) => (
          <ListItem
            button
            key={text}
            className={clsx(classes.row, {
              [classes.oddRow]: index % 2 === 0,
            })}
            onClick={() => props.handleClick(text)}
          >
            <ListItemIcon>
              <MenuBookOutlined />
            </ListItemIcon>
            <ListItemText primary={text.replace(".pdf", "")} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  return (
    <div>
      <React.Fragment key={"anchor"}>
        <div className={classes.docTreeButtonContainer}>
          <Tooltip title="Avaa etusivu">
            <Button
              variant="outlined"
              className={classes.docTreeButton}
              onClick={props.toggleHomeScreen()}
            >
              <HomeOutlined className={classes.menuButton} />
            </Button>
          </Tooltip>
          <Tooltip title="Avaa dokumenttilistaus">
            <Button
              variant="outlined"
              className={classes.docTreeButton2}
              onClick={props.toggleDocTree(true)}
            >
              <MenuBookOutlined className={classes.menuButton} />
            </Button>
          </Tooltip>
        </div>
        <Drawer
          anchor="right"
          open={props.show}
          onClose={props.toggleDocTree(false)}
        >
          <DrawerHeaderBar />
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
