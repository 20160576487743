import React, { Component } from "react";
import DocumentViewer from "./components/DocumentViewer";
import DocumentDrawer from "./components/DocumentDrawer";
import PageShuffler from "./components/PageShuffler";
import PirkanmaaAppBar from "./components/PirkanmaaAppBar";
import Footer from "./components/Footer";

import { Grid } from "@material-ui/core";

import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDocUrl: "",
      currentPage: 1,
      numberOfPages: 0,
      showDocTree: false,
      docListing: [],
      isHomePage: true,
    };
  }

  setNumberOfPages = (count) => {
    this.setState({ numberOfPages: count });
  };

  setCurrentPage = (page) => {
    this.setState({ currentPage: page });
  };

  toggleDocTree = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ showDocTree: open });
  };

  toggleHomeScreen = () => (event) => {
    window.location.reload();
    this.setState({ isHomePage: false });
  };

  async fetchDocListing() {
    // const url = "http://localhost:3010/docs";
    const url = "https://tieto.pirkanmaa.fi/docs";
    let resp = await fetch(url);
    let data = await resp.json();

    return data;
  }

  async componentDidMount() {
    const res = await this.fetchDocListing();
    const tmp = res.filesNames;
    this.setState({ docListing: tmp });
  }

  onNewDocSelected(name) {
    console.log("mä hanskaan.", name);
    const url = `https://tieto.pirkanmaa.fi/doc/?id=${name}`;
    this.setState({ currentDocUrl: url, isHomePage: false });
  }

  render() {
    return (
      <div className="App">
        <PirkanmaaAppBar />

        <DocumentDrawer
          show={this.state.showDocTree}
          toggleDocTree={(p) => this.toggleDocTree(p)}
          toggleHomeScreen={this.toggleHomeScreen}
          files={this.state.docListing}
          handleClick={(tt) => this.onNewDocSelected(tt)}
        />

        <PageShuffler
          currentPage={this.state.currentPage}
          numberOfPages={this.state.numberOfPages}
          setCurrentPage={(page) => this.setCurrentPage(page)}
          isHomePage={this.state.isHomePage}
        />

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            style={{ paddingLeft: 0, paddingRight: 0, width: "70%" }}
          >
            <DocumentViewer
              curDocUrl={this.state.currentDocUrl}
              currentPage={this.state.currentPage}
              numberOfPages={this.state.numberOfPages}
              setNumberOfPages={(count) => this.setNumberOfPages(count)}
            />
          </Grid>
        </Grid>
        <Footer />
      </div>
    );
  }
}
export default App;
