import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import SkipNext from "@material-ui/icons/SkipNext";
import SkipPrevious from "@material-ui/icons/SkipPrevious";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  shufflerButtonContainer: {
    display: "flex",
    zIndex: 20,
    maxWidth: 250,
    marginLeft: 5,
  },
  button: {
    margin: theme.spacing(0.2),
  },
  leftIcon: {
    marginRight: theme.spacing(0.1),
    fontSize: 32,
  },
  rightIcon: {
    marginLeft: theme.spacing(0.1),
    fontSize: 32,
  },
  iconSmall: {
    fontSize: 20,
  },
}));

const PageShuffler = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.shufflerButtonContainer}>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={() => props.setCurrentPage(props.currentPage - 1)}
        disabled={props.currentPage === 1 && props.isHomePage}
      >
        <Tooltip title="Edellinen sivu">
          <SkipPrevious className={classes.leftIcon} />
        </Tooltip>
      </Button>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={() => props.setCurrentPage(props.currentPage + 1)}
        disabled={props.currentPage === props.numberOfPages || props.isHomePage}
      >
        <Tooltip title="Seuraava sivu">
          <SkipNext className={classes.rightIcon} />
        </Tooltip>
      </Button>
    </div>
  );
};

export default PageShuffler;
