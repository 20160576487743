import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 0,
    flexDirection: "row",
    color: "ligthgrey",
    alignItems: "center",
    justifyContent: "center",
  },
  yhteystiedotHeader: {
    color: "black",
  },
  yhteystiedot: {
    color: "grey",
  },
  link: {
    color: "#6c757d",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={3} sm={3} lg={3}>
        <div className={classes.yhteystiedot}>
          <h4 className={classes.yhteystiedotHeader}>YHTEYSTIEDOT</h4>
          Pirkanmaan liitto,
          <br />
          Kelloportinkatu 1 B (käyntiosoite),
          <br />
          PL 1002, 33101 TAMPERE
          <br />
          p. 03 2481 111
          <br />
          pirkanmaan.liitto@pirkanmaa.fi
          <br />
          Henkilöstön s-posti: etunimi.sukunimi@pirkanmaa.fi
        </div>
      </Grid>
      <Grid item xs={3} sm={3} lg={3}>
        <div>
          <h4 className={classes.kunnatHeader}>PIRKANMAA</h4>

          <a
            href="http://www.akaa.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Akaa,&nbsp;
          </a>

          <a
            href="http://www.hameenkyro.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Hämeenkyrö,&nbsp;
          </a>
          <a
            href="http://www.ikaalinen.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Ikaalinen,&nbsp;
          </a>
          <a
            href="http://www.juupajoki.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <br />
            Juupajoki,&nbsp;
          </a>
          <a
            href="http://www.kangasala.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Kangasala,&nbsp;
          </a>
          <a
            href="http://www.kihnio.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Kihniö,&nbsp;
          </a>
          <br />
          <a
            href="http://www.lempaala.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Lempäälä,&nbsp;
          </a>
          <a
            href="http://www.mantta-vilppula.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Mänttä-Vilppula,&nbsp;
          </a>
          <br />
          <a
            href="http://www.nokiankaupunki.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Nokia,&nbsp;
          </a>
          <a
            href="http://www.orivesi.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Orivesi,&nbsp;
          </a>
          <a
            href="http://www.parkano.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Parkano,&nbsp;
          </a>
          <a
            href="http://www.pirkkala.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Pirkkala,&nbsp;
          </a>
          <a
            href="http://www.punkalaidun.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Punkalaidun,&nbsp;
          </a>
          <br />
          <a
            href="http://www.palkane.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Pälkäne,&nbsp;
          </a>
          <a
            href="http://www.ruovesi.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Ruovesi,&nbsp;
          </a>
          <a
            href="http://www.sastamala.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Sastamala,&nbsp;
          </a>
          <a
            href="http://www.tampere.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Tampere,&nbsp;
          </a>
          <a
            href="http://www.urjala.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Urjala,&nbsp;
          </a>
          <br />
          <a
            href="http://www.valkeakoski.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Valkeakoski,&nbsp;
          </a>
          <a
            href="http://www.vesilahti.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Vesilahti,&nbsp;
          </a>
          <a
            href="http://www.virrat.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Virrat,&nbsp;
          </a>
          <a
            href="http://www.ylojarvi.fi/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Ylöjärvi
          </a>
        </div>
      </Grid>
    </Grid>
  );
};

export default Footer;
